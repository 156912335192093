<script>

export default {
   
    data() {
        return {
            
            options2: [],
            value2: "",
        };
    },
    created() {

    },
    props: {
        id: {
            required: true,
            type: String,
        },
        name : {
            required: true,
            type: String,
        },
        placeholder: {
            type: String,
        },
        label: {

        },
        options: {

        },
        error: {

        },
        value: {},
        default_value: {
            default: true
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleInput(e) {
            console.log(e);
            // if (e.value != undefined) {
            //     this.$emit('input', e);
            // }
        },
        selected(e) {
            this.$emit('input', e);
        }
        // handleEnter() {
        //     if (this.onEnter) {
        //         this.onEnter();
        //     }
        // }
    },
    watch: {
        // value2(e) {
        //     // this.$emit('input', e);
        //     // if (e.value != undefined) {
        //     //     this.$emit('input', e.value);
        //     // }
        // },
        // value(newValue) {
        //     console.log(newValue);
        //     this.value2 = newValue;
        // },
    },
    mounted() {
    },
};
</script>

<template>
    <div>
        <label for="input-live" class="mb-0">{{ label }}:</label>
        <b-form-select @input="selected" :options="options" :value="value" :disabled="disabled">
            <template #first v-if="default_value">
                <b-form-select-option value="">Please select one</b-form-select-option>
            </template>
            <!-- <b-form-select-option :value="option.value" v-for="option in options" :key="option.value">{{ option.name }}</b-form-select-option> -->
        </b-form-select>
        <!-- <b-form-input
          :id="id"
          :name="name"
          :value="value"
          @input="handleInput"
          :placeholder="placeholder"
          trim
        >
        </b-form-input> -->
        <b-form-invalid-feedback id="input-live-feedback" :state="false" v-if="error && error.length > 0">
            {{ error[0] }}
        </b-form-invalid-feedback>
    </div>
</template>
